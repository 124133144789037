import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router'
Vue.use(VueRouter)
Vue.config.productionTip = false


// 3. 创建 router 实例，然后传 `routes` 配置
// 还可以传别的配置参数。
const router = new VueRouter({
  routes: [
    {
      path: "/",
      component: () => import("./components/redirect")
    },
    {
      path: "/pay",
      component: () => import("./components/pay")
    },
  ]
})


new Vue({
  render: h => h(App), router,
}).$mount('#app')
